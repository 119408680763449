
  
  li {
    list-style: none;
   
  }

  /* NAVBAR STYLING STARTS */
.navbar {
    display: flex;
    align-content: center;
     padding: 10px;  
     height: 5em;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    
    position: fixed !important;    
    background-color:  rgba(0, 0, 0, 0.2);       
    z-index: auto;      
    border-radius: 1%;
    
    

  }
  
  .nav-links, a {
    color:rgb(28, 28, 30) !important;
    
  }
  
  /* LOGO */
  .logo {
    font-size: 20px;
   
  }
  
  /* NAVBAR MENU */
  .menu {
    display:flex;   
    gap: 0.1em;
    font-size: 22px;  
    margin: 10px;
    
  }
  
   li:hover {
    background-color:white;
    border-radius: 4px;
    transition: 0.3s ease;
  }
  
  .menu ,li {
    padding: 1px 5px;
    
  }
  
  /* DROPDOWN MENU */
  .services {
    position: relative; 
  }
  
  .dropdown {
    background-color: rgb(1, 139, 139);
    padding: 1em 0;
    position: absolute; /*WITH RESPECT TO PARENT*/
    display: none;
    border-radius: 8px;
    top: 35px;
  }
  
  .dropdown, li  {
    margin-top: 0px;
  }
  
  .dropdown, li {
    padding: 0.5em 1em;
    width: 8em;
    text-align: center;
  }
  
  .dropdown, li:hover {
    background-color:rgb(72, 69, 69);
  
  }
  
  .services:hover, .dropdown {
    display: block;
  }

  input[type=checkbox] {
    display: none;
  } 
  
  /* HAMBURGER MENU */
  .hamburger {
    display: none;
    font-size: 24px;
    user-select: none;
  }
  
  /* APPLYING MEDIA QUERIES */
  @media (max-width: 768px) {
   .menu {
      display:none;
      background-color:gray;
      right: 0;
      left: 0;
      text-align: center;
      padding: 0px 0;
      z-index: 1030;
     
   
    }
  
    .menu, li:hover {
      background-color:transparent;
      transition: 0.3s ease;
    }
  
    .menu, li  {
      margin-top: 8px;
    }
  
  
    input[type=checkbox]:checked ~ ul .menu {
      display:grid;
    }
  

  
  
    .dropdown, li:hover {
      background-color:transparent;
    }
  }
  .image-m{
    width: 45px;
    height: 45px;
    border-radius: 20px;
    
  }

  
  .header-and-main-rows, .header-rows,.header-row-fix,.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;
}