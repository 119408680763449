@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lovers+Quarrel&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 1.6px;
    overflow: auto;
    
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

img {
    max-width: 100%;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

    .container .btn {
        margin-top: 20px;
        display: inline-block;
        padding: 10px 20px;
        border: none;
        border-radius: 10px;
        background: black;
        color: white;
        cursor: pointer;
        
    }

        .container .btn:hover {
            background: black;
            
        }

nav {
    
    margin-block-start: 10px;  
    margin-block-end: 10px;  
    padding: 0 10px;
    width: 100%;
    height: 70px;
    margin-bottom: 0.1;
    margin-top: 0.1;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.7;
    background-color: none;
     
}

.logo h1 {
    color: white;
    
}

nav .nav-items {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.nav-items ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    .nav-items ul li {
        list-style: none;
        margin-left: 20px;
        font-size: 22px;     
        color: gray;
        line-height: 4;
    }

        .nav-items, ul, li, a, p {
            color: #000;
        }

            .nav-items ul li a:hover {
                color: white;
                cursor: pointer;
                
                padding: 10px 20px;
    border-radius: 5px;
   
                
            }

            .nav-items ul li a.active {
                color: rgb(243, 59, 3);
            }
     

.banner-container {
    padding: 2% 0;
    display:flex;
    justify-content: center;
    align-items: center;
    gap: 10px;    
    background-image: linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5)),
                  url("./assets/logo\ y\ otros/foto.png");
    background-repeat:no-repeat;
    background-position: center;
    background-attachment: fixed;
}



.banner-container .btn {
    
    line-height: 25px;
    color: #747474;
  
    padding: 10px 20px;

    font-size: 13px;
 
}

 

.title  {
    
    font-size: 30px;
     margin: 40px 0;
     padding-top: 40px;
     
     text-align: center;
}

.cocktails-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 30px;
    margin: 20px 0;
}

    .cocktails-container .cocktail-card {
        background: #fff;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease-in-out;
    }






/* Single Product */
.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

    .flex-container .cocktail-img {
        width: 100%;
        height: 500px;
        object-fit: cover;
        object-position: center;
        border: 5px solid #b6c4cb;
        border-radius: 5px;
    }

.cocktail-infos .row {
    margin: 10px 0;
}

    .cocktail-infos .row .label {
        font-size: 100px;
        font-weight: 600;
        color: #000;
    }
 .logosLocales{
  border-radius: 40%;
  align-items: center;

    width: 70px;
    height: 130px;
}
@media (max-width:500px) {
    .logosLocales {
        
        align-items: center;
        height:30px;
        width:60px;
        

        
    }
}
@media (min-width:400px) {
     .alquiler{
        
        align-items: center;
         background: none;
        
    }
}
@media (max-width:1700px) {
    .ImLogo{
        padding-top: 0%;
        min-width: 100px;
        min-height: 100px;
        border-radius: 30%;
     
       
              
             
    }
  }
@media (min-width:400px) {
    .ImLogo{
        padding-top: 0%;
        max-width: 30px;
        max-height: 30px;
        border-radius: 30%;
     
       
              
             
    }
  }
 .alquiler{
    
    opacity: 8;
    background-size: cover;
    align-items: center;
    background-attachment: fixed;
    
 }
 .ImageLocal {
    width: 100%;
    height: 210px;
 }
 @media (max-width:900px) {
    .paraVerLocales{
        max-width: 250px;
        max-height: 250px;
        border-radius: 30%;  
       
              
             
    }
  }
  @media (min-width:400px) {
    .paraVerLocales{
        min-width: 200px;
        min-height: 200px;
        border-radius: 30%;  
        background: none;
              
             
    }
  }
.ImLogo {
    height: 100px;
    width: 140px;
}

.button-contact {
        background: #050505;
        text-transform: uppercase;        
        color: #fff;
        cursor: pointer;
        margin-top: 5px;
        
      }
      .button-contact{
        border-radius: 90px;
        padding: 15px 300px;
        margin-bottom: 15px;
        border: none;
        outline: none;
        font-size: 16px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        
       
      }
      form {
        
        margin-left: 5px;
        padding-inline: 0px;
        font-weight: 10;
        text-align: center;
      
        
        background: whitesmoke;
        
        
       
      }
      input{
     
       box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
       width: 100%;
       padding-top: 10px;
       padding-bottom: 10px;
       border-radius: 90px;
       
     
  
      }
     .form-control{
        margin-top: 25px;
        margin-bottom: 35px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: 10%;
        
     }
      .contact-container{
        width: 250%;
        min-height: 160px;
        height: 40vh;    
        place-content: center;       
        padding: 0;
        margin: 0;
        
        
      }

      
    .paraVerLocales{
width: 900px;
height: 550px;
    }
    .ImageSuenos{
        width: 100%;
        height: 450px;
        align-content: center;
    }
    html, body { 
        background: #f1f1f1; 
        font-family: 'Merriweather', 
        sans-serif;
         padding: 1em; } 
        h1 {
             text-align: center; 
             color: gray; }
              form { 
                max-width: 600px;
                 text-align: center;
                  margin: 20px auto; 
                  
                } 
                input, textarea { 
                    border: 0;
                     outline: 0; 
                     padding: 1em; 
                     border-radius: 8px;
                     display: block; 
                     width: 100%; 
                     margin-top: 1em; 
                     font-family: 'Merriweather', sans-serif; 
                     resize: none;
                 } 
                 #input-submit {
                     color: rgb(241, 239, 239);
                      background:gray; 
                      cursor: pointer; 
                      width: 100%;
                      padding-bottom: 1em;
                      
                    } 

                      #input-submit:hover { 
                        box-shadow: 0 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.2);
                        color: #00a8ff;
                        
                        
                     } textarea { height: 126px; }
                      .half {
                        
                         float: left; width: 48%; 
                         margin-bottom: 1em; } 
                         .right { 
                            width: 50%; 
                      
                           
                        } 
                         .left {
                             margin-right: 2%; }
                              @media (max-width: 480px) { 
                                .half { width: 100%;
                                     float: none; 
                                     margin-bottom: 0; } }
     .cf:before, .cf:after { 
        content: ' '; 
        display: table; } 
        .cf:after { clear: both; }
       
        .infiniteCarousel{
            padding-bottom: 70px;
        }
        #contact{
            background-image: linear-gradient(rgba(224, 53, 53, 0.5), rgba(255, 255, 0, 0.5)), url("./assets/Centro\ Comercial\ Tournón/Foto 5-3-22 08 59 40 (2).jpg");
            background-attachment: fixed;
        }
        #submit{

            height:3.2rem;
            width:14rem;
            font-size:1.2rem;
            border-radius:2rem;
            background:linear-gradient(to right,#380707,#ff5050);
            
            color:white;
            margin-top:1.2rem;
        }
      